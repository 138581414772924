<template lang="pug">
.om-wizard-top
  .om-wizard-top-holder.py-5
    om-logo-svg.cursor-pointer(@click.native="$emit('skip')")
  .om-wizard-top-segmented(v-show="showProgress")
    .row.no-gutters
      .col-4.d-flex.wizard-segment(v-for="segment in segments")
        progress-bar-segment(:segment="segment")
  .om-wizard-top-progress(:style="{ opacity: !showProgress ? 0 : 1 }")
    .om-wizard-top-progress-line(:style="progressWidth")
</template>

<script>
  import ProgressBarSegment from '@/components/Wizard/ProgressBarSegment.vue';
  import { segmentSettings } from '@/views/Wizard/flow';

  export default {
    components: { ProgressBarSegment },
    props: {
      showProgress: {
        type: Boolean,
        default: true,
      },
      percent: {
        type: Number,
        default: 1,
      },
    },
    data() {
      return {
        segments: segmentSettings,
      };
    },
    computed: {
      progressWidth() {
        return {
          width: `calc(${this.percent}% - 1.25vw)`,
        };
      },
    },
  };
</script>

<style lang="sass">
  .wizard-segment
    position: relative
    &::after
      content: ""
      display: block
      position: absolute
      width: 4px
      height: 20px
      background: white
      right: 0
      bottom: -21px
    &:last-child::after
      display: none
  .om-wizard-body
    position: relative
    z-index: 1
  .om-wizard-top
    &-segmented
      padding: 0 2.5vw
      margin-bottom: 0.5rem
    &-holder
      display: flex
      align-items: center
      height: 4.5rem
      padding-left: 2.5vw
      padding-right: 2.5vw

    &-progress
      height: 0.8rem
      width: 100%
      background: #F1F2F4

      &-line
        height: 100%
        width: 10%
        background: #ED5A29
        border-radius: 0px 0.5rem 0.5rem 0px
</style>
